import React from 'react';
import { camelCase } from 'lodash/fp';
import { IImageBlock } from '@whitelabel/helpers/types';
import { StyledImagesBlock, StyledImageBox, StyledImage } from './styledImagesBlock';
import getStylesBasedOnTotalImages from './helper';

interface IImagesBlockProps {
  images: IImageBlock[];
  className?: string;
}

const ImagesBlock = ({ images, className }: IImagesBlockProps): JSX.Element => {
  const {
    alignment,
    addMarginTopAfter,
    addMarginTopAfterSm,
    addMarginTopAfterMd,
    imageBoxMaxWidth,
    imageBoxMaxWidthSm,
    imageBoxMaxWidthMd,
    imageBoxMaxWidthLg,
    imageMaxWidth,
    imageMaxWidthSm,
    imageMaxWidthMd,
  } = getStylesBasedOnTotalImages(images.length);

  return (
    <StyledImagesBlock
      alignment={alignment}
      addMarginTopAfter={addMarginTopAfter}
      addMarginTopAfterSm={addMarginTopAfterSm}
      addMarginTopAfterMd={addMarginTopAfterMd}
      className={className}
    >
      {images.map(({ image, altText }: any) => (
        <StyledImageBox
          className="image-box"
          imageBoxMaxWidth={imageBoxMaxWidth}
          imageBoxMaxWidthSm={imageBoxMaxWidthSm}
          imageBoxMaxWidthMd={imageBoxMaxWidthMd}
          imageBoxMaxWidthLg={imageBoxMaxWidthLg}
          totalImages={images.length}
          key={camelCase(altText || image.title)}
        >
          <StyledImage
            className="image"
            imageMaxWidth={imageMaxWidth}
            imageMaxWidthSm={imageMaxWidthSm}
            imageMaxWidthMd={imageMaxWidthMd}
            alt={altText || image.title}
            src={image.file}
          />
        </StyledImageBox>
      ))}
    </StyledImagesBlock>
  );
};

export default ImagesBlock;
