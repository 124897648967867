import styled, { css } from 'styled-components';
import { Input } from 'reactstrap';
import { get } from '@whitelabel/helpers/style/get';
import { inputColors } from '@whitelabel/global-theme/colors';

export const StyledInput = styled(Input)`
  ${({ type }) =>
    type === 'textarea' &&
    css`
      && {
        height: 9.875rem;
      }
    `}
  && {
    padding-right: 0.9375rem;
  }

  border: ${get('borderWidth')} solid ${inputColors.border.default};
  background-color: ${inputColors.background.default};
  color: ${inputColors.value.default};

  &:hover:not(.is-invalid) {
    border: ${get('borderWidth')} solid ${inputColors.border.hover};
    cursor: pointer;
    background-color: ${inputColors.background.hover};
    color: ${inputColors.value.hover};
  }

  &:focus {
    border: ${get('borderWidth')} solid ${inputColors.border.focus};
    cursor: pointer;
    background-color: ${inputColors.background.focus};
    color: ${inputColors.value.focus};
  }

  &:active {
    border: ${get('borderWidth')} solid ${inputColors.border.active};
    background-color: ${inputColors.background.active};
    color: ${inputColors.value.active};
  }

  &:disabled {
    border: ${get('borderWidth')} solid ${inputColors.border.disabled};
    background-color: ${inputColors.background.disabled};
    color: ${inputColors.value.disabled};
  }
`;
