import { intlReducer as intl } from 'react-intl-redux';
import locales from '@whitelabel/reducers/locales';
import user from './user';
import customer from './customer';
import navs from './navs';

const appReducers = {
  intl,
  locales,
  user,
  customer,
  navs,
};

export default appReducers;
