import { all } from 'redux-saga/effects';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import { localesSaga } from '@whitelabel/actions/locales';
import { impersonateLoginSaga } from './impersonateLogin';
import { userSaga } from './user';
import { customerSaga } from './customer';

export function* rootSaga() {
  yield all([localesSaga(), impersonateLoginSaga(), userSaga(), customerSaga(), routinePromiseWatcherSaga()]);
}

export * from './impersonateLogin';
export * from './user';
export * from './customer';
