import styled, { css } from 'styled-components';
import Select from 'react-select';
import { rem } from 'polished';
import { getCSSArrow } from '@whitelabel/helpers/style/utils';
import { zIndex } from '@whitelabel/helpers/style/zIndex';
import { fontStyle, fontSize } from '@whitelabel/helpers/style/type';
import { color, gray } from '@whitelabel/helpers/style/colors';
import { borderRadius, getLeftValue } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { getThemeProp } from '@whitelabel/helpers/style/get';
import { dropdownColors, inputColors } from '@whitelabel/global-theme/colors';

export const StyledSelect = styled(Select).attrs({ classNamePrefix: 'react-select' })<{
  $rtl: boolean;
  reverseSelectionArrow: boolean;
  $hasIcon?: boolean;
}>`
  .label-ui {
    font-size: ${rem(9)};
    font-weight: bold;
    padding-top: ${rem(4)};
  }

  .react-select__control {
    height: ${rem(43)};
    font-size: 1.125rem;
    border-color: ${inputColors.border.default};
    border-radius: ${borderRadius('base')};
    cursor: pointer;

    ${mediaBreakpointUp('lg')`
      font-size: ${fontSize('lg')};
      height: ${rem(46)};
    `}

    .react-select__single-value {
      color: ${inputColors.value.default};
    }

    .react-select__placeholder {
      color: ${gray(600)};
    }

    &:focus-within {
      border-color: ${inputColors.border.focus};
      box-shadow: none;

      .react-select__single-value {
        color: ${inputColors.value.focus};
      }
    }
  }

  .react-select__control--is-disabled {
    background-color: ${inputColors.background.disabled};
    border-color: ${inputColors.border.disabled};
  }

  .react-select__single-value {
    width: 100%;
  }

  .react-select__single-value--is-disabled {
    color: ${inputColors.value.disabled};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    padding-inline-end: 1rem;

    svg {
      color: ${inputColors.icon.default};
    }
  }

  .react-select__value-container {
    padding-inline-start: ${(props) => (props.$hasIcon ? '2.5rem' : '0.75rem')};

    ${mediaBreakpointUp('md')`
      padding-inline-start: ${(props: { $hasIcon: boolean }) => (props.$hasIcon ? '2.75rem' : '1rem')};
    `}
  }

  .react-select__control,
  .react-select__control--is-focused,
  .react-select__control--menu-is-opened {
    ${({ invalid }) =>
      invalid &&
      css`
        border-color: ${inputColors.border.error};
      `}

    &:hover {
      border-color: ${inputColors.border.hover};

      ${({ invalid }) =>
        invalid &&
        css`
          border-color: ${inputColors.border.error};
        `}
    }
  }

  .react-select__menu {
    ${fontStyle('formValue')}
    z-index: ${zIndex('contentLayer')};
    margin-top: 0.75rem;
    ${({ reverseSelectionArrow }) => getCSSArrow(-20, 13, 'default', reverseSelectionArrow)};

    .react-select__option--is-focused {
      background-color: ${dropdownColors.focus};
    }

    .react-select__option--is-selected {
      color: ${inputColors.label.default};
      background-color: ${dropdownColors.selected};
    }
  }

  /* stylelint-disable-next-line order/order */
  ${({ isMulti }) =>
    isMulti &&
    css`
      .react-select__control {
        flex-direction: row-reverse;
        height: auto;
        min-height: 4rem;
        padding: 0.75rem 0;

        .react-select__clear-indicator {
          display: none;
        }

        .react-select__indicators {
          margin: 0 1rem 0 1.5rem;
        }
      }

      .react-select__value-container {
        padding-top: 0;
        padding-bottom: 0;
        padding-inline-start: 0;
      }

      .react-select__multi-value {
        background: ${color('white')};
        border-radius: 1.5rem;
        border: ${getThemeProp('borderWidth')} solid ${inputColors.border.default};
        align-content: center;

        .react-select__multi-value__label {
          font-size: 1.25rem;
          padding: 1px 0 1px 1rem;
        }

        .react-select__multi-value__remove {
          border-radius: 0 1rem 1rem 0;
          padding-inline-start: ${rem(5)};
          padding-inline-end: ${rem(5)};

          svg {
            color: ${inputColors.icon.default};
            padding: 0.25rem;
            width: 1.625rem;
            height: 1.625rem;
            border-radius: 1rem;
            transition: color 0.2s ease-out, background-color 0.2s ease-out;
          }

          &:hover {
            background: none;
            color: inherit;

            svg {
              color: ${inputColors.value.default};
              background-color: ${inputColors.background.hover};
            }
          }
        }
      }
    `}
`;

export const StyledCustomIcon = (
  Comp: React.FunctionComponent<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>,
) => styled(Comp)`
  position: absolute;
  height: 1rem;
  width: 1rem;
  ${getLeftValue('1rem')};
  top: 1rem;
  color: ${gray(600)};
  z-index: ${zIndex('content')};
`;

export const StyledRelativeWrapper = styled.div`
  position: relative;
`;
