import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { localizePathname } from '@whitelabel/helpers/i18n';
import { PackageComponentsContext } from '../../context/PackageComponents';
import { formatChildren, addPDSUTMSearchParams, PDSPolicyContext } from './helpers';

interface ITransformLinkProps {
  relativeLink: string;
  children: any;
  [key: string]: any;
}

const TransformLink = ({ relativeLink, children, ...props }: ITransformLinkProps) => {
  const intl = useIntl();
  const { locale } = intl;
  const { appendPDSUTMSearchParams, policyType, policyVersion } = useContext(PDSPolicyContext);
  const packageComponents = useContext(PackageComponentsContext);
  const NextJSLinkTo = packageComponents?.xcoverShared?.linkTo || packageComponents?.qbf?.scrollToAnchor;

  let href;
  if (relativeLink.startsWith('/#')) {
    href = relativeLink.substring(1);
  } else {
    href = localizePathname(relativeLink, locale);
  }

  if (appendPDSUTMSearchParams) {
    const pdsUTMCampaign = policyType && policyVersion ? `${policyType}_v${policyVersion}` : '';
    href = addPDSUTMSearchParams({
      href,
      pdsUTMCampaign,
    });
  }

  return NextJSLinkTo ? (
    <NextJSLinkTo {...props} {...{ href }} role="link">
      {ReactHtmlParser(formatChildren(children))}
    </NextJSLinkTo>
  ) : (
    <Link {...props} {...{ to: href }} role="link">
      {ReactHtmlParser(formatChildren(children))}
    </Link>
  );
};

export default TransformLink;
