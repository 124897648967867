import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { createError } from '../../helpers/api';

// Capture API error to Sentry and FS
export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    // Create the actual error with our standards and log it to Sentry with FS
    const error = createError(
      action.payload.status,
      action.payload.data,
      action.meta.baseQueryMeta?.request?.url,
      action?.meta?.baseQueryMeta?.response?.headers?.get('X-Request-Id'),
    );

    const serializedError = JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)));
    // set error as IError type
    action.payload.data = serializedError;
  }

  return next(action);
};
