import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { borderRadius } from 'polished';
import { fontSize } from '@whitelabel/helpers/style/type';
import { gray } from '@whitelabel/helpers/style/colors';
import { spacer } from '@whitelabel/helpers/style/units';
import { getThemeProp } from '@whitelabel/helpers/style/get';

const StyledCard = styled(Card)`
  ${({ horizontal, theme }) =>
    horizontal &&
    css`
      flex-direction: row;

      .card-img,
      .card-img-top,
      .card-img-bottom {
        flex: 1 1 auto;
        border-radius: 0;
      }

      .card-img-top {
        ${borderRadius('left', theme.borderRadii.base)}
      }

      .card-img-bottom {
        ${borderRadius('right', theme.borderRadii.base)}
      }

      .card-body {
        padding: ${spacer};
      }

      .card-title {
        font-size: ${fontSize('base')};
      }

      .card-subtitle {
        margin-bottom: 0;
      }
    `}

  ${({ muted }) =>
    muted &&
    css`
      background: ${gray(200)};
      border: ${getThemeProp('borderStyle')};
      box-shadow: none;
    `}

  &:last-child,
  .card-body > *:last-child,
  .card-body > .row:last-child .form-group:last-child {
    margin-bottom: 0;
  }
`;

StyledCard.propTypes = {
  horizontal: PropTypes.bool,
  muted: PropTypes.bool,
};

export default StyledCard;
