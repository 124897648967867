import React, { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { IUser, ICustomer } from '@whitelabel/helpers/types';
import { SOURCE_EMAIL, SOURCE, EU_CENTRAL_1 } from '@whitelabel/xcover-shared/helpers/constants';
import { getStoredCustomerRegion } from '@whitelabel/xcover-shared/helpers/multiRegion';
import * as actions from '../../../actions';
import ErrorBoundary from '../../ErrorBoundary';
import StyledLoading from '../../../styled/StyledLoading';

interface IAuthRouteProps {
  component: any;
  user?: IUser;
  customer?: ICustomer;
  loading: boolean;
  locale: string;
  logout: (...args: any[]) => any;
  key?: string;
}

const keepSearchIdPageKeys = ['paypalInterstitial'];

const AuthRoute = ({
  component: Component,
  user,
  customer,
  loading,
  locale,
  logout,
  key: pageKey,
  ...rest
}: IAuthRouteProps) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const source = urlParams.get(SOURCE) || SOURCE_EMAIL;
  const id = urlParams.get('id');
  const token = urlParams.get('signup_token');
  const queryStringRegion = urlParams.get('region');
  const region = getStoredCustomerRegion();

  // If mismatch between user and the id being passed sign the user out
  useEffect(() => {
    if ((id && user && user.username !== id) || !region) {
      logout();
    }
  }, [id, logout, user, region]);

  function renderComponent(props: any) {
    // If URL has id and token, send to signup confirm component to handle it along with source
    if (id && token) {
      return (
        <Redirect
          to={{
            pathname: `/${locale}/signup/confirm`,
            search: `?${new URLSearchParams({
              id,
              token,
              source,
              region: queryStringRegion || EU_CENTRAL_1,
            }).toString()}`,
            state: { referrer: location },
          }}
        />
      );
    }

    // User already logged in, show the component whatever it might be
    if (user && customer) {
      return (
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      );
    }

    // Otherwise redirect to login page with said referrer
    const referrer = { ...location };
    if (location.search) {
      const params = new URLSearchParams(location.search);
      params.delete('signup_token');
      if (pageKey && !keepSearchIdPageKeys.includes(pageKey)) params.delete('id');
      referrer.search = params.toString() ? `?${params.toString()}` : '';
    }

    const stateSource = (location.state as { source?: string })?.source;
    return (
      <Redirect
        to={{
          pathname: `/${locale}/login`,
          search: referrer.search,
          state: { referrer, ...(stateSource && { source: stateSource }) },
        }}
      />
    );
  }

  //  Simple loading state
  return loading ? <StyledLoading /> : <Route render={renderComponent} {...rest} />;
};

const mapStateToProps = (state: any) => {
  const { loading, data: customer } = state.customer;
  const { data: user } = state.user;
  return {
    user,
    loading,
    customer,
    locale: state.intl.locale,
  };
};

const mapDispatchToProps = {
  logout: actions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
